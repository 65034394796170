<template>
  <div class="merlends">
    <el-form
      ref="queryParams"
      :model="queryParams"
      :inline="true"
      @keyup.enter.native="handleQuery"
    >
      <el-form-item prop="keyWord">
        <el-input
          maxlength="32"
          v-model="queryParams.keyWord"
          placeholder="协议名称/编码"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleQuery"> 查 询 </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="agreementData"
      :header-cell-style="{ background: '#F2F3F5' }"
      border
    >
      <el-table-column prop="id" label="协议编号" align="center" />
      <el-table-column prop="name" label="协议名称" align="center" />
      <el-table-column prop="updatetime" label="最近修改时间" align="center">
        <template slot-scope="{ row }">
          <div>{{ row.updatetime || "-" }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="" label="操作" align="center">
        <template slot-scope="{ row }">
          <span class="btsed" @click="handleClick(row)">编辑</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 白条分佣设置弹窗 -->
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="850px"
    >
      <div style="height: 500px;">
        <!-- <div class="richText scorr-roll">
          <quill-editor
            ref="myQuillEditor"
            v-model="content.text"
            :options="editorOption"
            @change="onEditorChange($event)"
          />
        </div> -->
        <pre><Editor v-model="content.text" class="Editor" /></pre>
      </div>
      <div slot="footer" class="text-right">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="conserve">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Editor from "@/components/Editor/index.vue";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("Basics"); //vuex公共库
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
export default {
  components: {
    Editor,
  },
  data() {
    return {
      dialogVisible: false,
      title: "",

      queryParams: {
        keyWord: "",
      },
      content: {
        id: "1",
        text: "",
      },
      agreementData: [],
    };
  },
  computed: {
    ...commonIndex.mapState(["allJurisdiction"]),
  },
  created() {
    this.getList();
    // this.handleClick();
  },
  methods: {
    ...mapActions([
      "getagreementlist",
      "postagreementeditInfo",
      "getagreementeditInfo",
    ]),
    handleCurrentChange() {},
    async getList() {
      let res = await this.getagreementlist(this.queryParams);
      this.agreementData = res.data;
    },
    handleQuery() {
      this.getList();
    },
    // 弹窗
    async handleClick(row) {
      let data = await this.getagreementeditInfo({ id: row.id });
      data.data.id = data.data.id + "";
      this.content = data.data;
      this.title = row.name;
      this.content.id = row.id;
      this.dialogVisible = true;
    },
    // 保存
    async conserve() {
      let data = await this.postagreementeditInfo(this.content);
      if (data.code == 200) {
        this.$message({
          message: data.message,
          type: "success",
        });
        this.dialogVisible = false;
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 关闭
    toleave() {},
    // 富文本类容
    onEditorChange() {},
  },
};
</script>
<style lang="scss" scoped>
.editor {
  line-height: normal !important;
  //   height: 500px;
}
.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "请输入链接地址:";
}
.ql-snow .ql-tooltip.ql-editing {
  left: 100px !important;
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: "保存";
  padding-right: 0px;
}

.ql-snow .ql-tooltip[data-mode="video"]::before {
  content: "请输入视频地址:";
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "14px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: "10px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: "18px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: "32px";
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6";
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体";
}
</style>
<style lang="scss" scoped>
.MERCH {
  width: 100%;
  height: 100%;
  .merch-card {
    width: 100%;
    height: 100%;
    min-height: 600px;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
    .merch-tag {
      width: 100%;
      height: 40px;
    }
    .richText {
      margin-top: 30px;
      max-width: 800px;
      max-height: 85%;
      min-height: 50vh;
    }
    .compile-BTN {
      margin-top: 30px;
      width: 100%;
      display: flex;
      justify-content: center;
      .cancel-btn {
        width: 100px;
        height: 40px;
        line-height: 40px;
        background: #f5f6f7;
        color: #c6c7c9;
        text-align: center;
        border-radius: 5px;
        cursor: pointer;
      }
      .save-btn {
        margin-left: 20px;
        background: #06b7ae;
        color: #fff;
      }
    }
  }
}
.texts {
  display: flex;
  justify-content: center;
  max-height: 55vh;
}
::v-deep .el-tabs__item {
  width: 10.66vw;
  text-align: center;
}
</style>
